/************************
Locale Variables
************************/
@company-logo-size: 250px;
@company-logo-size-marged: @company-logo-size + 50;


#company-banner {
	position: relative;
	background-color: @brand-blue;

	&:after {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: -moz-linear-gradient(top,  rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%);
		background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 50%,rgba(0,0,0,1) 100%);
		background: linear-gradient(to bottom,  rgba(0,0,0,0) 50%,rgba(0,0,0,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
		opacity: .5;
	}

	.bg {
		opacity: .8;
		z-index: 0;
	}

	.container {
		position: relative;
		z-index: 5;
		padding-top: 250px;
		@media (max-width: @screen-sm-max) {
			padding-top: 90px;
		}
		@media (max-width: @screen-xs-max) {
			padding-top: 30px;
		}
	}

	.logo {
		@media (min-width: @screen-md-min) {
			float: left;
			margin-bottom: -70px;
		}
		position: relative;
		display: inline-block;
		width: @company-logo-size;
		height: @company-logo-size;

		padding: 0;

		background: #fff;
		box-shadow: 0px 28px 90px -10px rgba(0,0,0,0.4);
		img {
			max-height: 80%;
		  max-width: 80%;
		  width: auto;
		  height: auto;
		  position: absolute;
		  top: 0;
		  bottom: 0;
		  left: 0;
		  right: 0;
		  margin: auto;
		}
	}

	.infos {
		.clearfix;
		margin-left: @company-logo-size-marged;

		@media (max-width: @screen-sm-max) {
			margin: 30px 0;
		}
		&,
		a,
		h1, .h1 {
			color: #fff;
		}
		h1, .h1 {
			line-height: .8;
			margin-bottom: 30px;
			.small {
				color: #fff;
				font-size: 1.25rem;

			}
		}
		.keypoint {
			width: 30%;
			float: left;
			font-family: @headings-font-family;
			font-size: 1rem;
			line-height: 1.9;
			&.share {
				line-height: 1.3;
				width: 40%;
				@media (max-width: @screen-xs-max) {
					width: 100%;
				}
			}
			@media (max-width: @screen-xs-max) {
				width: 100%;
				& + .keypoint {
					margin-top: 15px;
				}
			}
		}
	}


	.shareBox {
	    float: none;
	}
	.share, .set_cookie {
		display: block;
		position: relative;
	    > span {
	        display: block;
	        margin: 0;
	        padding: 10px 0;
	        padding-left: 30px;
	        width: 100%;
	    }
	    > .icon, > .fa {
			position: absolute;
			top: 10px;
			left: 0;
	    }
	}


}

.company-nav {
	min-height: 70px;
	background-color: #282828;

	.inner-nav {
		margin-left: @company-logo-size-marged;
		@media (max-width: @screen-sm-max) {
			margin: 0;
		}
	}

	ul {
		margin: 0;
		li {
			line-height: 70px;
			@media (max-width: @screen-xs-max) {
				line-height: 40px;
			}
		}
	}
	ul.menu {
		margin-left: -20px;
		float: left;
		@media (max-width: @screen-xs-max) {
			float: none;
			margin: 0;
		}
		li {
			@media (max-width: @screen-xs-max) {
				display: block;
			}
			a {
				display: inline-block;
				padding: 0 20px;
				color: #fff;
				@media (max-width: @screen-xs-max) {
					display: block;
				}
			}
		}
	}
	ul.social {
		li {
			a{
				font-size: 1.5rem;
			}
		}
	}
	.social-menu {
		li {
			a {
				color: #fff;
				&:hover {
					color: lighten(@brand-blue, 30%);
				}
			}
		}

	}
}

.site.company {
	position: relative;
	@media (max-width: @screen-sm-max) {
		div[class*="col-"] {
			& + div[class*="col-"] {
				margin-top: 15px;
			}
		}
	}
}

.company-doc {
	display: block;
	margin: 20px 0;
	font-size: 0;
	.fa {
		display: inline-block;
		vertical-align: middle;
		padding-right: 25px;
		width: 70px;
		font-size: 50px;
		line-height: 1em;
	}
	span {
		display: inline-block;
		vertical-align: middle;
		width: calc(~"100% - 70px");
		font-size: 1.125rem;
	}
}

.awards {
	h2 {
		margin-bottom: 85px;
	}
}


/************************************
 References
************************************/
#carousel-ref {
	display: block !important;
	> .item {
		float: left;
		max-width: 285px;
	}
}
.references {

	.owl-stage {
		display: flex;
		flex-wrap: wrap;
	}
	.owl-item {
		background: #fff;
	}
	div.item {
		figure {
			position: relative;
			height: 0;
			padding: 0 0 60%;
			background: #fff;
			img {
				max-height: 100%;
			  max-width: 100%;
			  width: auto;
			  height: auto;
			  position: absolute;
			  top: 0;
			  bottom: 0;
			  left: 0;
			  right: 0;
			  margin: auto;
			}
		}
		.reference--title {
			margin-bottom: .8em;
		}
		.desc {
			padding: 30px;
			p {
				font-size: .875rem;
				line-height: 1.5em;
			}
		}

		&.no-img {
			position: relative;
			width: 100%;
			height: 100%;
			text-align: center;
			display: table;
			vertical-align: middle;
			.desc {
				display: table-cell;;
				vertical-align: middle;
			}
		}
	}
}

.references {
	.owl-dots {
		@media (max-width: @screen-xs-max) {
			display: none;
		}
	}
}

.casestudies {
	.row {
		padding: 25px;
		background: #fff;
		box-shadow: 0 10px 50px rgba(0,0,0,.2);
		@media (max-width: @screen-xs-max) {
			padding: 25px 0;
		}
		&+ .row {
			margin-top: 70px;
		}
	}
	div[class*="col-"]{
		padding:25px;
	}
	.owl-stage {
		display: flex;
		flex-wrap: wrap;
	}
	.item.logo {
		position: relative;
		height: 100%;
		img {
			max-width: 80%;
			max-height: 80%;
			width: auto;
			height: auto;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
		}
	}

	h3 {
		margin: 0;
		color: #262626;
		text-transform: uppercase;
		font-size: 1.13rem;
		font-weight: 700;
		line-height: 1.33em;
	}
	p {
		margin: 0 0 25px;
		color: #262626;
		font-size: 1rem;
		line-height: 1.5em;
		&:last-child {
			margin-bottom: 0;
		}
	}
}


.actualites {
	font-size: 0;
	div[class*="col-"] {
		float: none;
	    display: inline-block;
	    vertical-align: top;
		margin-bottom: 20px;
		padding: 5px;
		font-size: 16px;
	}
	.item {
		background: #fff;
		.desc {
			padding: 30px;
			.item-title {
				margin-bottom: .8em;
			}
			.date {
				color: #888;
			}
			p {
				color: #888;
				line-height: 1.5em;
			}
		}
	}
}

.team {
	.item {
		margin-bottom: 20px;
		overflow: hidden;
		box-shadow: 0 2px 4px rgba(0,0,0,.2);
		figure {
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		.desc {
			color: #262626;
			font-size: .875rem;
			line-height: 1.5em;
			padding: 30px;
			.name {
				margin-bottom: 0;
				color: #262626;
				font-size: 1.13rem;
				font-weight: 700;
				line-height: 1.33em;
				text-transform: uppercase;
			}
			.type {
				font-size: 1.5rem;
			}
			a {
				color: #262626;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		@media (min-width: @screen-xs-min){
			figure {
				width: 50%;
				float: left;
			}
			.desc {
				margin-left: 50%;
			}
		}
		&.contact-princ {
			background-color: @brand-blue;
			.desc {
				color: #fff;
				.type, .name, a {
					color: #fff;
				}
			}
		}
	}
}

.contact-form {
	textarea {
		height: 317px;
	}
	.btn {
		.pull-right;
		margin-top: 50px;
	}
	.col-md-6.pull-right {
		@media (max-width: @screen-sm-max) {
			float: none !important;
			margin-top: 15px;
		}
	}
}


.section-header {
	margin-bottom: 40px;
}
