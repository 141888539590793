.view-id-visiteurs_entreprises {
	padding: 80px 0;
	&:nth-child(odd) {
		background: #fff;
	}
	.view-content {
		.container;
		font-size: 0;
	}
	.views-row {
		font-size: 1rem;
		display: inline-block;
		vertical-align: top;
		width: 100%;
		margin-bottom: 30px;
		padding: 0 5px;
		@media (min-width: @screen-sm-min){
			width: 50%;
		}
		@media (min-width: @screen-md-min){
			width: 33.333%;
		}
		@media (min-width: @screen-lg-min){
			width: 25%;
		}
		> a {
			display: block;
			border: 1px solid #e5e5e5;
			min-height: 350px;
			background: #fff;

		}
	}

	.logo,
	.image{
		position: relative;
		display: inline-block;
		width: 100%;
		height: 200px;
		padding: 0;



		img {
			max-height: 90%;
		  max-width: 80%;
		  width: auto;
		  height: auto;
		  position: absolute;
		  top: 0;
		  bottom: 0;
		  left: 0;
		  right: 0;
		  margin: auto;
		}
	}
	.desc {
		h2 {
			.h4;
			margin: 0;
		}
		padding: 30px;
	}
}
