.results {
	.row {
		margin-bottom: 30px;
		&:nth-child(odd) {
			background: rgba(0,0,0,.05);
		}
	}

	.logo {
		@media (min-width: @screen-sm-min) {
			float: left;
		}
		position: relative;
		display: inline-block;
		width: @company-logo-size;
		height: @company-logo-size;

		padding: 0;

		background: #fff;
		box-shadow: 0px 2px 8px rgba(0,0,0,0.1);
		img {
			max-height: 80%;
		  max-width: 80%;
		  width: auto;
		  height: auto;
		  position: absolute;
		  top: 0;
		  bottom: 0;
		  left: 0;
		  right: 0;
		  margin: auto;
		}
	}

	h2 {
		margin-bottom: 15px;
	}

	.desc {
		.clearfix;
		margin-top: 40px;
		margin-left: @company-logo-size-marged;
		@media (max-width: @screen-md-max) {
			margin-top: 0;
			padding: 30px 0;
		}
		@media (max-width: @screen-xs-max) {
			margin-left: 0;
		}
	}

	.categories {
		@media (max-width: @screen-sm-max) {
			margin-top: 15px;
		}
	}

	.bookmark {
		@media (max-width: @screen-md-max) {
			margin-top: 15px;
			> ul {
				margin-bottom: 0;
			}
		}
	}

}
