@media print {
	a[href]:after {
		content: "";
	}

	header {
		height: auto;
	}

	.trigger-menu, .company-nav, #company-banner .infos .keypoint.share, section.site.media, section.site.awards, footer#footer, .maj-wrapper {
		display: none !important;
	}
	
	#company-banner {
		.logo {
			display: block;
			float: none;
			margin: 20px 0;
			height: auto;
			width: auto;
			img {
				position: relative;
				margin: 0;
				max-height: none;
				width: 250px;
				max-width: 100%;
				top: auto;
				right: auto;
				bottom: auto;
				left: auto;
			}
		}
		.container {
			padding-top: 0;
		}
	}

	#company-banner .infos {
		margin-left: 0;
	}

	section.site {
		padding: 0;
	}

	.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
		margin-top: 30px;
		margin-bottom: 15px;
	}

	.masque {
		display: block;
	}
}