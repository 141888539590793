
section {
	&.site {
		padding: 80px 0;
		&:nth-child(odd) {
			background: #fff;
		}
	}
}

.page-header {
	margin: 0;
	padding: 60px 0;
	color: #fff;
	background: @brand-primary;
	h1,h2,h3,h4,h5,h6,
	.h1,.h2,.h3,.h4,.h5,.h6 {
		color: #fff;
	}
}

.container {
	&.md {
		max-width: 970px;
	}
}

.inner-content {
	position: relative;
	padding: 30px;
	background: #fff;
	@media (min-width: @screen-md-max) {
		padding: 60px 115px;
		.full {
			margin-top: 60px;
			margin-left: -100px;
			margin-right: -100px;
		}
	}
}

.lined {
  border-top: 1px solid #e5e5e5;
  div[class^="col-"]:not(:first-child){
    border-left: 1px solid #e5e5e5;
    @media (max-width: @screen-xs-max) {
		border-left: 0;
		border-top: 1px solid #e5e5e5;
    }
  }
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
	margin-top: 0;
	margin-bottom: 1.5rem;
}
h2, .h2 {
}

ol, ul {
	padding-left: 20px;
}
ul.space {
	li {
		padding-top: 5px;
		padding-bottom: 5px;
	}
}
ul.arrow {
	li {
		list-style-image: url(../img/bullet-arrow.png);
	}
	&.big-lineheight {
		li {
			margin-bottom: 30px;
		}
	}
}

.css-col {
	@media (min-width: @screen-sm-min){
		&--2 {
			-webkit-column-count: 2;
	    -moz-column-count: 2;
	    column-count: 2;
	    -webkit-column-gap: 30px;
	    -moz-column-gap: 30px;
	    column-gap: 30px;
		}
		&--3 {
			-webkit-column-count: 2;
		    -moz-column-count: 2;
		    column-count: 2;
		    -webkit-column-gap: 30px;
		    -moz-column-gap: 30px;
		    column-gap: 30px;
			@media (min-width: @screen-md-min){
				-webkit-column-count: 3;
		    -moz-column-count: 3;
		    column-count: 3;
			}
		}
	}
}

.cat-list {
	@media (min-width: @screen-sm-min){
		-webkit-column-count: 2;
	    -moz-column-count: 2;
	    column-count: 2;
	    -webkit-column-gap: 30px;
	    -moz-column-gap: 30px;
	    column-gap: 30px;
	}
	@media (min-width: @screen-md-min){
		-webkit-column-count: 3;
	    -moz-column-count: 3;
	    column-count: 3;
	}
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		display: inline-block;
		position: relative;
		margin: 0 0 30px;
		padding: 0 0 0 20px;
		width: 100%;
		background-image: url(../img/bullet-arrow.png);
		background-position: left top 10px;
		background-repeat: no-repeat;
	}
}

.serv-list {
	@media (min-width: @screen-sm-min){
		-webkit-column-count: 2;
	    -moz-column-count: 2;
	    column-count: 2;
	    -webkit-column-gap: 30px;
	    -moz-column-gap: 30px;
	    column-gap: 30px;
	}
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		display: inline-block;
		position: relative;
		margin: 0 0 10px;
		padding: 0 0 0 20px !important;
		width: 100%;
		background-image: url(../img/bullet-arrow.png);
		background-position: left top 10px;
		background-repeat: no-repeat;
	}
}

.list-entrp {
	li {
		padding: 0 0 0 20px;
		background-image: url(../img/bullet-arrow.png);
		background-position: left top 10px;
		background-repeat: no-repeat;
		list-style: none !important;
	}
}

.no-gap {
	padding: 0;
}


.v-align {
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translate(0,-50%);
	-moz-transform: translate(0,-50%);
	-ms-transform: translate(0,-50%);
	-o-transform: translate(0,-50%);
	transform: translate(0,-50%);
}
.sm-marge {
	margin-left: 5px;
	margin-right: 5px;
}
.sm-gap {
	padding-left: 5px;
	padding-right: 5px;
}
.no-gap {
	padding-left: 0;
	padding-right: 0;
}
.bg {
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.masque {
	display: none;
}

#slider{
	.item-video {
		position: relative;
		width: 100%;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		.owl-video-wrapper,
		.owl-video-frame {
			position: absolute;
			top:0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.owl-video-tn {
			background-size: cover;
		}
	}

	iframe{
		position: absolute;
		top:0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// Animate
// .animated {
//   .animated;
// }
.os-animation {
	opacity: 0;
	@media (max-width: @screen-xs-max) {
		-webkit-animation-delay: 0s !important;
		-moz-animation-delay: 0s !important;
		-ms-animation-delay: 0s !important;
		-o-animation-delay: 0s !important;
		animation-delay: 0s !important;
	}
}

// Content Body
.content-body {
	position: relative;
	max-width: 900px;
	margin: 0 auto;
	padding: 60px 15px 90px;

	// All
	& > * {
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
	}

	// Aside
	& > aside {
		max-width: 900px;
	}

	// Colonnes
	& > .row {
		margin-bottom: @line-height-computed;

		 .col-md-6:first-child {
			padding-left: 0;
			@media (max-width: @screen-sm-max) {
				padding-right: 0;
			}
		}

		 .col-md-6:last-child {
			padding-right: 0;
			@media (max-width: @screen-sm-max) {
				padding-left: 0;
			}
		}
	}

	p[style*="text-align: center;"] {
		img {
			display: inline-block;
		}
	}
}

// Lead
p.lead {
	font-size: 1.33em;
	line-height: 1.5em;
	color: @brand-primary;
	font-weight: 300;
	margin-bottom: @line-height-computed;
}

// Evidence
p.evidence, div.evidence {
	color: #fff;
	background-color: @brand-primary;
	padding: @line-height-computed*2;
	margin-bottom: @line-height-computed;
	@media (max-width: @screen-sm-max) {
		padding: 30px;
	}
	@media (max-width: @screen-xs-max) {
		padding: 15px;
	}

	a {
		color: #fff;
	}

	a.btn {
		background-color: #fff;
		color: @brand-primary;

		&:hover {
			background-color: darken(#fff, 10%);
		}
	}
}

// Img
img {
	max-width: 100%;
	height: auto;

	&[style*="left"] {
		margin-right: @line-height-computed;
		margin-bottom: @line-height-computed;
	}

	&[style*="right"] {
		margin-left: @line-height-computed;
		margin-bottom: @line-height-computed;
	}
}

// Table
table {
	border: 1px solid #d8d8d8;
	margin-bottom: @line-height-computed;
	width: 100%;

	th {
		background-color: @brand-primary;
		color: #fff;
		text-transform: uppercase;
		font-weight: 400;
	}

	td {
		background-color: #f0f0f0;
	}

	tr.even td {
		background-color: darken(#f0f0f0, 5%);
	}

	td, th {
		padding: @line-height-computed/2 @line-height-computed;
	}
	
	@media (max-width: @screen-sm-max){
		display: block;
	    width: 100% !important;
	    overflow-x: auto;
	}
}

// Iframe responsive
.iframe-embed {
	display: block;
	position: relative;
	margin-bottom: @line-height-computed;
	padding: 0;
	padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

	iframe {
		position: absolute;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    border: 0;
	}
}

// Boutons
.btn-default {
	.button-variant(#fff; @brand-primary; @brand-primary);
}

.btn1 {
	background-color: @brand-primary;
	color: #fff;
}

.btn2 {
	background-color: @brand-primary;
	color: #fff;
}


body.page-user, body.page-search404, body.page-search, body.node-type-webform, body.page-node-done {
	#main .page-content {
		.container;
		max-width: 900px;
		padding-top: 45px;
		padding-bottom: 60px;
	}
}

body.page-user {
	.form-control {
		max-width: 600px;
		@media (max-width: @screen-xs-max) {
			max-width: 200px;
		}
	}
}

.item-award {
	position: relative;
	margin: 5px 0;
	min-height: 270px;
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
	.img-wrapper {
		position: relative;
		height: 180px;
		img {
		    position: absolute;
		    top: 50%;
		    left: 50%;
		    -webkit-transform: translate(-50%,-50%);
		    -moz-transform: translate(-50%,-50%);
		    -ms-transform: translate(-50%,-50%);
		    -o-transform: translate(-50%,-50%);
		    transform: translate(-50%,-50%);
		    height: auto;
		    max-height: 100%;
		    width: auto;
		    max-width: 100%;
		}
	}
	h3 {
		margin: 0;
		color: #232323;
		font-size: 1.13rem;
		font-weight: 700;
		line-height: 1.33em;
		text-transform: uppercase;
	}
	.content {
		padding: 20px 15px;
	}
	&:not(.award-img) {
		.content {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			-webkit-transform: translate(0,-50%);
		    -moz-transform: translate(0,-50%);
		    -ms-transform: translate(0,-50%);
		    -o-transform: translate(0,-50%);
		    transform: translate(0,-50%);
		}
	}
}


.maj-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	.maj {
		float: right;
		padding: 15px 25px;
		background-color: fade(@brand-yellow, 20%);
		color: @brand-blue;
		font-family: @headings-font-family;
		font-size: 0.88rem;
		font-weight: 400;
		line-height: 1.43em;
	}
}


.ellipses {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}


body.page-search404, body.page-search {
	#main {
		.search-form {
			.form-control, .input-group-btn {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				max-width: 100%;
			}
			.form-control {
				@media (max-width: @screen-xs-max) {
					width: 100%;
				}
			}
			.input-group-btn {
				margin-left: 10px;
				@media (max-width: @screen-xs-max) {
					margin: 10px 0 0;
				}
			}
		}
		.search-result {
			.title {
				margin: 0;
			}
			p {
				margin: 0;
				&.search-info {
					font-style: italic;
				}
			}
			& + .search-result {
				margin-top: 30px;
			}
		}
	}
}