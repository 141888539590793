//
// Variables
// --------------------------------------------------
@brand-primary:   #c71432;
@brand-blue:      #354789;
@brand-yellow:    #F2C301;


@body-bg: #f3f3f3;

@line-height-base: 1.875;

@font-family-sans-serif:  "Lato", sans-serif;
@headings-font-family:    "Josefin Sans", sans-serif;
@headings-font-weight:    400;
@headings-line-height:    1.1;
@headings-color:          @brand-blue;


@nav-link-hover-bg:                         transparent;
