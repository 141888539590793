// Footer
footer#footer {
padding: 100px 0;
  &,
  a {
    color: #fff;
  }
  background: @gray-dark;
  .initiative {
  	.pull-right {
  		@media (max-width: @screen-sm-max) {
			float: none !important;
			margin-top: 30px;
		}
  	}
  }
  @media (max-width: @screen-xs-max) {
	img {
		display: block;
	}
  }
}
