// Header
header {
    position: relative;
    z-index: 1000;
    height: 75px;
    background: #fff;

    .logo {
        float: left;
        padding: 10px 0 0;
        @media (max-width: @screen-md-max) {
            padding-top: 17px;
        }
        @media (max-width: @screen-xs-max) {
            padding-top: 10px;
        }
        img {
            width: 245px;
            height: auto;
            @media (max-width: @screen-md-max) {
                width: 160px;
            }
            @media (max-width: @screen-xs-max) {
                width: 220px;
            }
        }
    }

    .login{
        float: left;
        display: inline-block;
        padding: 25px 15px !important;
        line-height: 25px !important;
        @media (max-width: @screen-md-max) {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        @media (max-width: @screen-sm-max) {
            float: none;
        }
    }

    #nav {
        float: right;

        .menu {
            margin-right: 40px;
            @media (max-width: @screen-md-max) {
                margin-right: 0;
            }
            li {
                a {
                    padding: 25px 15px !important;
                    line-height: 25px !important;
                    @media (max-width: @screen-md-max) {
                        padding-left: 10px !important;
                        padding-right: 10px !important;
                    }
                }
                &.active,
                &.active-trail {
                    a {
                        color: @brand-primary;
                    }
                }
                ul {
                    border: 0;
                    li {
                        a {
                            padding: 10px !important;
                        }
                    }
                    &.dropdown-menu {
                        padding: 0;
                        > .active {
                            a {
                                background-color: #f6e4e7;
                            }
                        }
                    }
                }
            }

        }
        a:not(.btn, .opt) {
            color: #232323;
            &:hover {
                color: @brand-primary;
            }
        }
        .btn {
            padding: 10px 15px;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 1px;
            line-height: 20px;
            @media (max-width: @screen-md-max) {
                padding-left: 10px;
                padding-right: 10px;
            }

        }
        .mainNav {
            .btn {
                float: left;
                margin: 17px 0 18px;
                @media (max-width: @screen-sm-max) {
                    float: none;
                }
            }
        }
    }

    .lang {
        float: left;
        margin: 17px 0 18px 20px;
        @media (max-width: @screen-md-max) {
            margin-left: 10px;
        }
        @media (max-width: @screen-sm-max) {
            float: none;
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                float: left;
                @media (max-width: @screen-sm-max) {
                    display: inline-block;
                }
                a {
                    display: block;
                    height: 40px;
                    width: 40px;
                    border-radius: .25rem;
                    color: @brand-primary;
                    font-family: @font-family-sans-serif;
                    font-size: 1.13rem;
                    font-weight: 400;
                    line-height: 40px;
                    text-align: center;
                    text-decoration: none;
                }
                &:first-child {
                    a {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
                &:last-child {
                    a {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
                &.active, &:hover {
                    a {
                        background-color: @brand-primary;
                        color: #fff;
                    }
                }
                &.active {
                    a {
                        cursor: default;
                    }
                }
            }
        }
    }
}

.not-front {
    header {
        height: 150px;
        #nav {
            height: 150px;
            border-left: 1px solid #e5e5e5;
            .mainNav {
                height: 75px;
                padding-left: 20px;
                border-bottom: 1px solid #e5e5e5;
                @media (max-width: @screen-md-max) {
                    padding-left: 0;
                }
            }
        }
    }
}

.trigger-menu {
    position: absolute;
    z-index: 1;
    top: 25px;
    right: 30px;
    z-index: 1;
    @media (max-width: @screen-xs-max) {
        right: 15px;
    }
    svg {
    	display: block;
        fill: @brand-primary !important;
    	rect {
    		-webkit-transform-origin: left center;
    		-moz-transform-origin: left center;
    		-ms-transform-origin: left center;
    		-o-transform-origin: left center;
    		transform-origin: left center;
    		-webkit-transform: rotate(0);
    		-moz-transform: rotate(0);
    		-ms-transform: rotate(0);
    		-o-transform: rotate(0);
    		transform: rotate(0);
    		.transition(all .2s);
    	}
    }
    &.open {
    	svg {
    		rect {
                fill: #fff !important;
    			&:nth-child(1) {
    				-webkit-transform: rotate(45deg);
    				-moz-transform: rotate(45deg);
    				-ms-transform: rotate(45deg);
    				-o-transform: rotate(45deg);
    				transform: rotate(45deg);
    				x: 3px;
    			}
    			&:nth-child(2) {
    				width: 0%;
    				opacity: 0;
    			}
    			&:nth-child(3) {
    				-webkit-transform: rotate-(45deg);
    				-moz-transform: rotate(-45deg);
    				-ms-transform: rotate(-45deg);
    				-o-transform: rotate(-45deg);
    				transform: rotate(-45deg);
    				x: 3px;
    			}
    		}
    	}
    }
}

@media (max-width: @screen-sm-max) {
header {
    height: 75px !important;
    ul,li {float: none !important;}
    .login {display: block;}
    .menu {margin: 0 !important;}
}

    nav#nav {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: table;
        width: 100% !important;
        height: 100% !important;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        background-color: fade(@brand-primary,90%);
        overflow-x: auto;
        overflow-y: scroll;
        //.transition(all .35s);
        .mainNav {
            height: auto !important;
            margin: 75px 0;
            padding: 0;
            border: 0 !important;
            a {
                color: #fff !important;
            }
        }
    }
}

body.open-menu {
    @media (max-width: @screen-sm-max) {
        height: 100%;
        overflow: hidden;
        nav#nav {
            visibility: visible;
            opacity: 1;
        }
    }
}
.shareBox {
    position: relative;
    float: left;
    .addthis_toolbox {
        display: none;
        position: absolute;
        z-index: 10;
        top: 100%;
        left: 0;
        margin: 10px 0 0;
        padding: 0;
        list-style: none;
        li {
            a {
                display: block;
                margin: 0;
                padding: 0;
                height: 48px;
                width: 48px;
                background-color: #fff;
                color: #000 !important;
                font-size: 1.13rem;
                line-height: 48px;
                text-align: center;
                .transition(all .2s);
                span {
                    display: none;
                }
                &:hover {
                    background-color: @brand-primary;
                    color: #fff !important;
                    text-decoration: none;
                }
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }
}
.share {
    display: block;
    color: #5c7e91;
    font-size: 1.13rem;
    line-height: 1.11em;
    text-decoration: none !important;
    .transition(.2s all);
    span {
        display: inline-block;
        width: calc(~"100% - 35px");
        margin-left: 10px;
    }
    .icon {

    }
    &:hover, &.active {
        color: @brand-primary;
    }
}


.fixed-cta {
    position: fixed;
    z-index: 300;
    top: 150px;
    right: 0;
    padding: 10px 15px;
    background-color: @brand-blue;
    border-radius: 5px 0 0 5px;
    color: #fff !important;
    font-size: 0;
    @media (max-width: @screen-sm-max) {
        top: 120px;
    }
    @media (max-width: @screen-xs-max) {
        top: 90px;
        padding: 10px;
    }
    .transition(all .2s);
    .fa, span {
        display: inline-block;
        vertical-align: middle;
    }
    .fa {
        font-size: 1.5rem;
        line-height: 1em;
    }
    span {
        padding-left: 15px;
        max-width: 300px;
        font-size: 0.9rem;
        line-height: 1.1em;
        overflow: hidden;
        white-space: nowrap;
        .transition(all .2s);

        @media (max-width: @screen-xs-max) {
            padding-left: 10px;
        }
        strong {
            font-size: 1rem;
        }
    }
    &.scrolled {
        span {
            padding-left: 0;
            max-width: 0;
        }
    }
    &:hover {
        background-color: darken(@brand-blue, 15%);
        span {
            padding-left: 15px;
            max-width: 300px;
            @media (max-width: @screen-xs-max) {
                padding-left: 10px;
            }
        }
    }
}
