@import 'https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,400i,700';
@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon.eot?11an6k');
    src:    url('../fonts/icomoon.eot?11an6k#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?11an6k') format('truetype'),
        url('../fonts/icomoon.woff?11an6k') format('woff'),
        url('../fonts/icomoon.svg?11an6k#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

// FontAwesome library.
@import '../bower_components/fontawesome/less/font-awesome';

// OwlCarousel library.
@import "../bower_components/owl.carousel/dist/assets/owl.carousel.css";
@import "../bower_components/owl.carousel/dist/assets/owl.theme.default.css";

// BS
@import "bootstrap-select";
@import 'icomoon';

// Bootstrap library.
@import 'bootstrap';
// Bootstrap em.
@import 'bootstrap-em';

// Base-theme overrides.
@import 'overrides';

// Theme specific.
@import 'search';
@import 'header';
@import 'actualites';
@import 'home';
@import 'content';
@import 'results';
@import 'company';
@import 'directory';
@import 'footer';

// Print
@import 'print';