header {
  .search {
    clear: both;
    @media (max-width: @screen-sm-max) {
        padding: 0 15px 60px;
    }
    .form-control {
      height: auto !important;
      margin:0;
    }
    label {display: none;}
    .form-type-select {
        width: 40%;
        float: left;
        @media (max-width: @screen-xs-max) {
          float: none;
          width: 100%;
        }
    }
    .form-submit {
        width: 20%;
        @media (max-width: @screen-xs-max) {
          float: none;
          width: 100%;
        }
    }

    .btn.dropdown-toggle {
      padding: 10px 30px 10px 20px !important;
      height: 75px !important;
      font-size: 1rem !important;
      letter-spacing: 0 !important;
      text-transform: none !important;
      border-radius: 0 !important;
      background: transparent !important;
      @media (max-width: @screen-xs-max) {
        padding: 10px 20px 10px 10px !important;
      }
      .filter-option {
        text-overflow: ellipsis;
      }
    }

    .btn-default, .btn-primary {
      height: 75px;
      border-radius: 0 !important;
      background: @brand-yellow;
    }




  }//.search
}//header

.search {
  /***********
  Flipcard
  ***********/
  .container {
    position: relative;
    height: 100%;

    > .inner {
      .v-align;
      position: relative;
      width: 100%;
      height: 300px;
      margin: -60px auto 0;
      perspective: 800px;

      @media (min-width: @screen-sm-min){
        width: ~"calc(100% - 100px)";
        height: 250px;
      }
      @media (min-width: @screen-md-min){
        height: 150px;
      }
    }
  }
  .phrase {
    display: table-cell;
    vertical-align: middle;
    line-height: 2.5rem;
  }
  .bootstrap-select.btn-group .dropdown-menu {
    @media (max-width: @screen-xs-max){
      width: 270px;
    }
  }
  &--card {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 1s;

    .bootstrap-select {
      width: auto !important;
      border-radius: 0;
      box-shadow: none;
      height: auto;
      vertical-align: top;
      &.open {
        .dropdown-toggle {
          box-shadow: none !important;
        }
      }

      .dropdown-toggle {
        .h2;
        font-weight: 700;
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 0;
        margin: 0;
        background: transparent;
        span {
          overflow:visible !important;
          text-decoration: underline;
        }
        &:focus {
          outline: none !important;
        }
        &:active {
          box-shadow: none !important;
        }
        .bs-caret {
          .caret {
            border-top-width: .4rem;
            border-right-width: .4rem;
            border-left-width: .4rem;
          }
        }
      }
    }

    .face {
      margin: 0;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      -webkit-backface-visibility: hidden;
         -moz-backface-visibility: hidden;
           -o-backface-visibility: hidden;
              backface-visibility: hidden;

      &--front {
        .h2;
        z-index: 0;
        background: #fff;
      }
      &--back {
        z-index: 1;
        background: #fff;
        transform: rotateX( 180deg );
      }
    }

    &.flipped {
      transform: rotateX( 180deg );
    }
  }
  .advanced-search {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: 20px;
    color: #fff;
    -webkit-transform: translate(-50%,0);
    -moz-transform: translate(-50%,0);
    -ms-transform: translate(-50%,0);
    -o-transform: translate(-50%,0);
    transform: translate(-50%,0);
  }
}

#contracteo-recherche-avancee-form {
  > div {
    position: relative;
    display: table-cell;
    vertical-align: middle;
  }

  .form-item-produits-services {
    display: inline-block;
    width: 70%;
    margin-bottom: 0;
    @media (max-width: @screen-xs-max) {
      width: 100%;
    }
  }

  .input-group-addon,
  label {display: none;}

  input#edit-produits-services {
    display: inline;
    width: 90%;
    margin: 0 auto;
    border: 0;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    @media (max-width: @screen-xs-max) {
      width: 100%;
    }
  }
  button {
    display: inline-block;
  }
}
#autocomplete {
  li:hover {
    background: #f5f5f5;
  }
}
