// Home
#leaderboard {
  position: relative;
  background: #000;
  .bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(../img/leaderboard-fr.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 0;
    opacity: .9;
  }
  .search {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 600px;
    @media (max-width: @screen-xs-max) {
      height: 440px;
    }
    form {
      display: table;
      width: 100%;
      height: 100%;
      
    }

  }
  &.nl {
    .bg {
      background: url(../img/leaderboard-nl.jpg) no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
}

#intro {
  .inner-content {
    @media (min-width: @screen-sm-min) {
      padding-top: 150px;
    }
  }
  @media (max-width: @screen-xs-max) {
    img {
      display: block;
    }
  }
}

#assets {
  .inner-content {
    padding-top: 0;
  }
}

#newsletter {
  .inner-content {
    background-color: #c90e2d;
  }
  .cta {
    text-align: right;
    @media (max-width: @screen-sm-max) {
        text-align: left;
        margin-top: 30px;
    }
  }
  h2 {
    margin: 0;
    color: #fff;
    font-family: @headings-font-family;
    font-size: 2.25rem;
    font-weight: 400;
    line-height: normal;
    @media (max-width: @screen-sm-max) {
        font-size: 1.9rem;
    }
  }
  .btn {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    border: 2px solid #fff;
    border-radius: 10px;
    padding: 25px 30px;
    background-color: transparent;
    color: #fff;
    font-family: @headings-font-family;
    font-size: 0.95rem;
    font-weight: 700;
    letter-spacing: .5px;
    line-height: normal;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    white-space: normal;
    .transition(all .2s);
    &:hover {
      background-color: #fff;
      color: #c90e2d;
    }
  }
}

blockquote {
  border:0;
  padding: 40px 70px;
  font-weight: 100;
  @media (max-width: @screen-md-max) {
    padding-left: 20px;
    padding-right: 20px;
  }
  &,
  a {
    color: #fff;
  }
  a {
    text-decoration: underline;
    &:hover {
      color: fade(#fff, 60%);
    }
  }
  background: @brand-blue;

  @media (min-width: @screen-sm-min) {
    position: absolute;
    top: -50px;
    right: 0;
    width: 75%;
    margin: 0;
  }
  @media (min-width: @screen-md-min) {
    width: 50%;
  }
}

.trio {
  margin-top: 0 !important;
  margin-bottom: -60px;
  @media (max-width: @screen-md-max) {
    margin-top: 30px;
    margin-bottom: -30px;
  }
  > div {
    text-align: center;
    padding: 60px 40px;
    min-height: 250px;
    @media (max-width: @screen-sm-max) {
      padding-left: 20px;
      padding-right: 20px;
    }
    p {
      margin-top: 30px;
    }
    @media (max-width: @screen-xs-max) {
      img {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

img.img-2x{
  display: inline-block;
  width: 110px;
  height: auto;
}


/*****
 Les entreprises
*****/
#companies {
  .inner-content {
    background: transparent;

    .cta {
      display: block;
      padding: 50px 45px 30px;
      min-height: 270px;
      color: #fff;
      font-family: @headings-font-family;
      text-decoration: none;
      background: #222;
      @media (max-width: @screen-sm-max) {
        padding-left: 35px;
        padding-right: 35px;
      }
      @media (max-width: @screen-xs-max) {
        padding-left: 20px;
        padding-right: 20px;
      }


      &--companies {
        background: @brand-blue;
        &:hover {
          background: darken(@brand-blue, 10%);
        }
      }
      &--actualites {
        background: #0e71b8;
        &:hover {
          background: darken(#0e71b8, 10%);
        }
      }
      &--addmycompany {
        background: @brand-primary;
        &:hover {
          background: darken(@brand-primary, 10%);
        }
      }
    }
  }
}
