.view-id-visiteurs_actualites {
	padding: 80px 0;
	&:nth-child(odd) {
		background: #fff;
	}
	div.view-content {
		.container;
		font-size: 0;
	}
	div.views-row {
		font-size: 1rem;
		display: inline-block;
		vertical-align: top;
		width: 100%;
		margin-bottom: 30px;
		padding: 0 5px;
		@media (min-width: @screen-sm-min){
			width: 50%;
		}
		@media (min-width: @screen-md-min){
			width: 33.333%;
		}
		@media (min-width: @screen-lg-min){
			width: 25%;
		}
	}
	.item {
		display: block;
		border: 1px solid #e5e5e5;
		min-height: 350px;
		background: #fff;
		.desc {
			padding: 30px;

			/* These are technically the same, but use both */
			overflow-wrap: break-word;
			word-wrap: break-word;

			-ms-word-break: break-all;
			/* This is the dangerous one in WebKit, as it breaks things wherever */
			word-break: break-all;
			/* Instead use this non-standard one: */
			word-break: break-word;

			/* Adds a hyphen where the word breaks, if supported (No Blink) */
			-ms-hyphens: auto;
			-moz-hyphens: auto;
			-webkit-hyphens: auto;
			hyphens: auto;
			.item-title {
				margin-bottom: .8em;
			}
			.date {
				color: #888;
			}
			p {
				color: #888;
				line-height: 1.5em;
			}
		}
	}
}

.node-type-actualites {
	.page-content {
		/* These are technically the same, but use both */
		overflow-wrap: break-word;
		word-wrap: break-word;

		-ms-word-break: break-all;
		/* This is the dangerous one in WebKit, as it breaks things wherever */
		word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;

		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}
}